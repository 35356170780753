import React from 'react'

export default function ContentPage(props) {
  return (
    <section className="content">
        <div className="page-heading">
            <h1>{props.content.title}</h1>
            <ol className="breadcrumb">
                <li><a>API</a></li>
                <li><a>{props.content.title}</a></li>
            </ol>
        </div>
        <div className="page-body">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="align-justify" dangerouslySetInnerHTML={{__html: props.content.description}}></div>
              </div>
            </div>
            {(() => {
              let div ;
              if(props.content.api != null){
                div=(
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <h3>Example</h3>
                      <table className='table table-bordered'>
                        <tbody>
                          <tr>
                            <th>URL :</th>
                            <td>{window.location.origin}{props.content.api.url}</td>
                          </tr>
                          <tr>
                            <th>Method :</th>
                            <td>{props.content.api.method}</td>
                          </tr>
                          
                        </tbody>
                      </table>
                      {(() => {
                        let section ;
                        if(props.content.api.header != null){
                          section = (<><h4>Header :</h4>
                          <pre>{props.content.api.header}</pre></>)
                        }
                        return section;
                      })()}
                      {(() => {
                        let section ;
                        if(props.content.api.request != null){
                          section = (<><h4>Request :</h4>
                          <pre>{props.content.api.request}</pre></>)
                        }
                        return section;
                      })()}
                      {(() => {
                        let section ;
                        if(props.content.api.response != null){
                          section = (<><h4>Response :</h4>
                          <div className='well' dangerouslySetInnerHTML={{__html : props.content.api.response}}></div></>)
                        }
                        return section;
                      })()}
                      {(() => {
                        let section ;
                        if(props.content.api.error != null){
                          section = (<><h4>Bad Request or Error :</h4>
                          <pre>{props.content.api.error}</pre></>)
                        }
                        return section;
                      })()}
                      
                      
                      
                      
                    </div>
                  </div>
                );
              }
              return div;
              
              })()}
            
          </div>
        </div>
    </section>
  )
}
