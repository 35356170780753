import React from 'react'

export default function Sidebar(props) {
  return (
    <aside className="sidebar">
      <nav className="sidebar-nav">
          <ul className="metismenu">
            {(() => {
                let li = [];
                for (let i = 0; i < props.links.length; i++) {
                    li.push(
                        <li key={props.links[i].key} className={props.links[i].state}>
                            <a onClick={() => props.changeContentData(props.links[i].link)} className={props.links[i].clas} >
                                <i className="material-icons">{props.links[i].icon}</i>
                                <span className="nav-label">{props.links[i].title}</span>
                            </a>
                            <ul>
                                {(() => {
                                    let _li = [];
                                    if(props.links[i].subLinks.length > 0){
                                        for (let _i = 0; _i < props.links[i].subLinks.length; _i++) {
                                            _li.push(<li className={props.links[i].subLinks[_i].state} key={props.links[i].key}><a onClick={() => props.changeContentData(props.links[i].subLinks[_i].link)}>{props.links[i].subLinks[_i].title}</a></li>);
                                        }
                                    }
                                    return _li;
                                })()}
                            </ul>
                        </li>
                    );
                }
                return li;
            })()}
          </ul>
      </nav>
    </aside>
  )
}
