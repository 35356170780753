
import  './App.css';
import Apis_json from './Apis.json';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ContentPage from './components/ContentPage';
// import Footer from './components/Footer';
import React, { useState } from 'react'



function App() {
  const [contentData, setContentData] = useState(Apis_json[0]);
  
  const changeContentData = (link) => {
    if(Apis_json.length > 1){
      Apis_json.forEach((item) => {
        item.state = "";
        if(link != "" && item.link == link ){
          item.state = "active";
          setContentData(item);
        }
        else{
          item.subLinks.forEach((subitem) => {
            if(link != "" && subitem.link == link ){
              item.state = "active";
              subitem.state = "active";
              setContentData(subitem);
            }
            else{
              subitem.state = "";
            }
          });

        }
      });
    }
  }
  return (
    <>
      {/* <Header /> */}
      <Sidebar links={Apis_json} changeContentData={changeContentData} />
      <ContentPage content={contentData} />
      {/* <Footer /> */}
    </>
  );
}

export default App;
